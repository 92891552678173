import v1 from "./v1.js";
import v2 from "./v2.js";
import v3 from "./v3.js";
import v4 from "./v4.js";
import v5 from "./v5.js";
import v6 from "./v6.js";
import v7 from "./v7.js";
import v8 from "./v8.js";
import v9 from "./v9.js";
import v10 from "./v10.js";

export default [v1, v2, v3, v4, v5, v6, v7, v8, v9, v10];
